import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, merge } from 'rxjs';
import { CopilotServiceService } from 'src/app/core/services/copilot-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.css']
})
export class ChatbotComponent {

  studyId!: string;

  constructor(
    private copilotService: CopilotServiceService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (sessionStorage.getItem('isLoggedIn') && sessionStorage.getItem('isLoggedIn') === 'true') {
      this.route.queryParams.subscribe(p => {
        this.studyId = p['studyId'];
        this.initializeWebChat();
      });
    } else {
      this.router.navigate(['/'], { queryParamsHandling: 'merge'} );
    }
   
  }

  async initializeWebChat() {
    try {
      const tokenUrl = environment.tokenEndpointURL.split('?')[0];;
      let url = new URL(environment.tokenEndpointURL);
      const apiVersion = url.searchParams.get('api-version') ?? '2022-03-01-preview'; // Fallback to 'default-version' if api-version is not found
      
      const additionalParams = { 'studyid': this.studyId };
      const directLineDetails = await firstValueFrom(this.copilotService.getDirectLineDetails(tokenUrl, apiVersion, additionalParams));
      
      // Ensure directLineDetails is not undefined
      if (!directLineDetails) {
        throw new Error('Failed to retrieve Direct Line details.');
      }

      const { directLineURL, token } = directLineDetails;
      const locale = document.documentElement.lang || 'en';
      const styleOptions = {
        hideUploadButton: true,
        accent: '#00809d',
        botAvatarBackgroundColor: '#FFFFFF',
        botAvatarImage: 'https://bot-framework.azureedge.net/bot-icons-v1/908e1a90-a6aa-469e-b1aa-ffaaba9b6da0_Gr0CYQ4KPEOi106FjsDuk2Pi6YJDatGGt8Md3V94vcmN8Oc.png',
        botAvatarInitials: 'BT',
        userAvatarImage: 'https://content.powerapps.com/resource/makerx/static/media/user.417aa99d.svg',
        userAvatarInitials: 'U'
      };

      const directLine = window.WebChat.createDirectLine({
        domain: new URL('v3/directline', directLineURL).toString(),
        token
      });

      const subscription = directLine.connectionStatus$.subscribe({
        next: (value: number) => {
          if (value === 2) {  // 2 indicates connected
            directLine.postActivity({
              from: { id: 'user-id', name: 'User Name' },
              name: 'startConversation',
              type: 'event',
              locale: locale,
              value: this.studyId
            }).subscribe();

            subscription.unsubscribe();  // Unsubscribe once the event is sent
          }
        }
      });

      window.WebChat.renderWebChat({
        directLine,
        locale,
        styleOptions
      }, document.getElementById('webchat') as HTMLElement);
    } catch (error) {
      console.error('Failed to initialize Web Chat:', error);
    }
  }
}
